import react, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';
import SubscriptionWall from './SubscriptionWall';
import '../Styles/magazineStyle.css';

function Magazine() {
    const [magazines, setMagazines] = useState([]);
    const [isSubscribed, setIsSubscribed] = useState(false);

    const auth = getAuth();
    const db = getFirestore();
    const storage = getStorage();

    const formatDate = (timestamp) => {
        const date = timestamp.toDate(); 
        return date.toLocaleDateString(); 
    };

    useEffect(() => {
        checkSubscription();
        fetchMagazines();
    }, []);

    //Fetch initiatives and display them
    const fetchMagazines = async () => {
        try {
            const magazinesCollectionRef = collection(db, "Magazines");
            const querySnapshot = await getDocs(magazinesCollectionRef);
            const magazinesData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setMagazines(magazinesData);
        } catch (error) {
            console.error("Error fetching Magazines:", error);
        }

    };

    const checkSubscription = async () => {
        try {
            const user = auth.currentUser;
            const userDocRef = doc(db, "Users", user.uid);
            const userDoc = await getDoc(userDocRef);
            const userData = userDoc.data();

            if (userData.batch === 'Batch1') {
                setIsSubscribed(true);
                return;
            }

            if (!user) {
                setIsSubscribed(false);
                return;
            }

            const response = await fetch('https://mmp3cpgdp3.eu-west-2.awsapprunner.com/check-any-subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userEmail: user.email }),
            });

            const data = await response.json();
            setIsSubscribed(data.isSubscribedToAnyItem);
        } catch (error) {
            console.error('Error checking subscription status:', error);
            setIsSubscribed(false); // In case of an error, assume not subscribed
        }
    };

    if (!isSubscribed) {
        return <SubscriptionWall />;
    }

    return (
        <div className="magazinesContainer">
            <div className="magazineHeaderContainer">
                <h1>Magazines</h1>
                <p>The magazine is a our voice of what is going on in TCC and celebrate the exciting things that are happening in TCC</p>
            </div>
            
            <div className="magazineContainer">
                {magazines.length > 0 ? (
                    magazines.map((magazine) => (
                        <Link 
                            key={magazine.id} 
                            to={`/magazine/${magazine.id}`}
                            style={{ textDecoration: 'none' }}
                        >
                            <div key={magazine.id} className="magazineCard">
                                {magazine.image ? (
                                    <img src={magazine.image} alt="Magazine cover photo" className="magazineImage" />
                                ) : (
                                    <div className="magazineImage">
                                        <h2>TCC <br /> Magazine</h2>
                                    </div>
                                )}
                                <div className="magazineDetails">
                                    <h2>{magazine.title} - {formatDate(magazine.date)}</h2>
                                    <p>{magazine.summary}</p>
                                    <button>Read more...</button>
                                </div>
                            </div>
                        </Link>
                    ))
                ) : (
                    <p>No Magazines.</p>
                )}
            </div>

            <div className="addInitiative">
                <Link
                    to={`/create-magazine`}
                    style={{ textDecoration: 'none' }}
                >
                    <button>+</button>
                    <p>Add Magazine</p>
                </Link>
            </div>
        </div>
    );
}

export default Magazine;