import react, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import '../Styles/createFormStyle.css';

function AddMagazine() {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [date, setDate] = useState('');
    const [image, setImage] = useState(null);
    const [summary, setSummary] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const db = getFirestore();
    const storage = getStorage();

    const handleImageUpload = async () => {
        if (!image) return null;

        const storageRef = ref(storage, `magazines/${image.name}`);
        await uploadBytes(storageRef, image);
        return getDownloadURL(storageRef);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const imageUrl = await handleImageUpload();
            const magazineData = {
                title,
                body,
                date: new Date(date),
                image: imageUrl || null,
                summary,
                status: 'Pending',
                isFeatured: false,
                type: '',
            };

            const magazinesCollectionRef = collection(db, 'Magazines');
            await addDoc(magazinesCollectionRef, magazineData);

            alert('Magazine added successfully!');
            setTitle('');
            setBody('');
            setDate('');
            setImage(null);
            setSummary('');
        } catch (error) {
            console.error('Error adding magazine:', error);
            alert('Error adding magazine. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const validation = async (e) => {

    };

    return(
        <div className="addIntiativeContainer">
            <h1>Create Magazine</h1>
            <div className="createItemForm">
                <form onSubmit={handleSubmit} className="addMagazineForm">
                    <label>
                        <p>Title:</p>
                        
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        <p>Summary:</p>
                        
                        <input
                            type="text"
                            value={summary}
                            onChange={(e) => setSummary(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        <p>Body:</p>
                        
                        <textarea
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        <p>Date:</p>
                        
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            required
                        />
                    </label>
                    
                    <label>
                        <p>Image:</p>
                        
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </label>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Add Magazine'}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default AddMagazine;