import '../Styles/loginStyle.css';
import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getDocs, collection } from 'firebase/firestore';
import { auth, db } from '../firebase';
import Message from './Message';
import ForgotPassword from './ForgotPassword';

function Login() {
    const [currentView, setCurrentView] = useState('login');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [error, setError] = useState(null);
    const auth = getAuth();

    const [mfaCode, setMfaCode] = useState('');
    const [isMfaRequired, setIsMfaRequired] = useState(false);

    // Function to handle switching between login and sign up views
    const handleSwitchView = (event) => {
        event.preventDefault();
        setCurrentView(currentView === 'login' ? 'signUp' : 'login');
    };

    const validatePassword = () => {
        if (password !== confPassword) {
            console.log("Passwords do not match!");
            setError("Passwords do not match!");
            return;
        };
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

        } catch (error) {
            console.log(error.message);
            setError(error.message);
        }
    };

    const handleMfaValidation = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/validate-mfa', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ code: mfaCode }),
            });
            if (response.ok) {
                console.log('MFA validation successful');
                // Proceed to the dashboard or next step
            } else {
                setError('Invalid MFA code');
            }
        } catch (error) {
            console.log(error.message);
            setError(error.message);
        }
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();
        setCurrentView('forgotPassword');
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        
        if (password !== confPassword) { 
            setError("Passwords do not match!");
            return;
        };

        if (!termsAccepted) {
            setError("Please accept the terms and conditions.");
            return;
        };

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const usersSnapshot = await getDocs(collection(db, "Users"));
            const userCount = usersSnapshot.size;

            let batch = "";
            if (userCount <= 50) {
                batch = "Batch1";
            } else if (userCount <= 200) {
                batch = "Batch2";
            } else if (userCount <= 1000) {
                batch = "Batch3";
            } else {
                const batchNumber = Math.ceil(userCount / 2000) + 2; // Dynamically assign larger batches
                batch = `Batch${batchNumber}`;
            }

            await setDoc(doc(db, "Users", user.uid), {
                uId: user.uid,
                email: user.email,
                username: username,
                points: 0,
                batch: batch,
                firstName: '',
                lastName: '',
                address: '',
                phoneNumber: '',
                profilePicture: '',
                coverPhoto: '',
                signUpDate: new Date(), 
            });

            const response = await fetch('https://mmp3cpgdp3.eu-west-2.awsapprunner.com/create-customer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, email }),
            });
            
            const data = await response.json();

            if (response.ok) {
                console.log(`Customer created successfully! Customer ID: ${data.customer.id}`);
            } else {
                alert(data.error || 'Something went wrong');
            }    

            if (!response.ok) {
                throw new Error('Failed to create Stripe customer');
            }
            
            console.log("User register successful!")
        } catch (error) {
            console.log(error.message);
            setError(error.message);
        }
    };

    const toggleTermsAccepted = () => {
        setTermsAccepted(!termsAccepted);
    };

    return (
        <div className="bodyContainer">
            {error && <Message className="errorContainer" header="Error" body={error} onClose={() => setError(null)} />}
            <div className="loginContainer">
                <h1>TCC</h1>

                <div className="login" style={{ display: currentView === 'login' ? 'block' : 'none' }}>
                    <h2>Welcome! Let's Login</h2>
                    <form>
                        <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
                        <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required />
                        <button className="forgotPassword" onClick={handleForgotPassword}>Forgot Password?</button>
                        <div className="buttonContainer">
                            <button onClick={handleSwitchView}>Register</button>
                            <button type="submit" onClick={handleLogin}>Login</button>
                        </div>

                    </form>
                </div>
                <div className="signUp" style={{ display: currentView === 'signUp' ? 'block' : 'none' }}>
                    <h2>Sign Up</h2>
                    <form>
                        <input type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)} required />
                        <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
                        <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required />
                        <input type="password" placeholder="Confirm Password" onChange={(e) => setConfPassword(e.target.value)} required />
                        <label className="termsCheckBox">
                            <input type="checkbox" onChange={toggleTermsAccepted} /> I accept the terms and conditions
                        </label>
                        <div className="buttonContainer">
                            <button onClick={handleSwitchView}>Login</button>
                            <button type="submit" onClick={handleSignUp}>Sign Up</button>
                        </div>
                    </form>
                </div>

                <div style={{ display: currentView === 'forgotPassword' ? 'block' : 'none' }}>
                    <ForgotPassword />
                    <button className="backBtnLogin" onClick={(e) => setCurrentView('login')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m0 0l6 6m-6-6l6-6" /></svg>
                        Back
                    </button>
                </div>
            </div>
            <div className="overlay" style={{ display: error ? 'block' : 'none', width: '200vw' }}></div>
        </div>
    )
}

export default Login;