import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import '../Styles/subscriptionWall.css';

function SubscriptionWall() {
    const [subscriptions, setSubscriptions] = useState([]);
    const [stripePromise, setStripePromise] = useState(null);
    const [loading, setLoading] = useState(true);

    const currencySymbols = {
        gbp: '£',
        usd: '$',
        eur: '€',
    };

    useEffect(() => {
        fetch("https://mmp3cpgdp3.eu-west-2.awsapprunner.com/config").then(async (r) => {
            const { publishableKey } = await r.json();
            setStripePromise(loadStripe(publishableKey));
        });
    }, []);

    useEffect(() => {
        // Fetch Subscription Items from backend (which interacts with Stripe)
        async function fetchSubscriptions() {
            try {
                const response = await fetch('https://mmp3cpgdp3.eu-west-2.awsapprunner.com/get-subscription-items');
                const data = await response.json();
                const sortedSubscriptions = data.subscriptionItems.sort((a, b) => a.unitAmount - b.unitAmount);
                
                setSubscriptions(sortedSubscriptions || []);

            } catch (error) {
                console.error("Error fetching subscriptions:", error);
            } finally {
                setLoading(false);
            }
        }

        fetchSubscriptions();
    }, []);

    const handleSubscribe = async (subscriptionId) => {
        try {
            // Make API call to process the payment for the selected subscription
            const response = await fetch('https://mmp3cpgdp3.eu-west-2.awsapprunner.com/create-subscription-checkout-session', {
                method: 'POST',
                body: JSON.stringify({ subscriptionId }),
                headers: { 'Content-Type': 'application/json' },
            });

            const data = await response.json();
    
            if (data.id && stripePromise) {
                const stripe = await stripePromise;
                await stripe.redirectToCheckout({ sessionId: data.id });
            } else {
                console.error("Failed to create a Stripe session");
            }
        } catch (error) {
            console.error("Error during subscription process:", error);
        }
    };

    return (
        <div className="subscriptionWallContainer">
            <h1>Subscribe</h1>
            <p className="description">Join the community, access limitless opportunities for imaginative people
            passionate about building beautiful, absolutely free, superabundant communities.</p>
            <div className="subscriptionItemContainer">
                {loading ? (
                    <p>Loading subscriptions...</p>
                ) : subscriptions.length > 0 ? (
                    subscriptions.map((subscription) => (
                        <div key={subscription.priceId} className="subscriptionCard">
                            <h2>{subscription.name}</h2>
                            <p>
                                {currencySymbols[subscription.currency] || subscription.currency} 
                                {subscription.unitAmount}<span className="currency"> / {subscription.interval}</span>
                            </p>
                            <p>{subscription.description}</p>
                            <button onClick={() => handleSubscribe(subscription.priceId)}>
                                Subscribe
                            </button>
                        </div>
                    ))
                ) : (
                    <p>No subscription options found.</p>
                )}
            </div>
        </div>
    );
}

export default SubscriptionWall;
