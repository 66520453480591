import react, { useState, useEffect } from 'react';
import '../Styles/learnMoreStyle.css';
import team from '../Images/team.jpg';

function LearnMore() {

    return(
        <div className="learnMoreContainer">
            {/* Background Image */}

            {/* Title */}
            <div className="learnMoreTitle">
                <img className="backgroundImage" src={team} alt="background image" />
                <div className="textContainer">
                    <h1>Take back control of your finances.</h1>
                    <p>TCC boldly create access to limitless opportunities for vetted and valiantly imaginative people
                    passionate about building beautiful, absolutely free, superabundant communities.</p>
                </div>
            </div>

            {/* Body */}
            <div className="learnMoreBodyText">

                <p>TCC’s commonwealth approach rests upon the personal and corporate genius of a
                    Community built by its members and for its members. Now, from within the Community,
                    members do what they used to do outside –– buy, sell, start a business, etc. All purchases,
                    exchanges, and transactions protect and enhance value for investors, members, friends,
                    families, businesses, and our local areas.

                    <br /><br />
                    TCC’s commonwealth model begins by pairing two values: a) existing assets of members and
                    investors that they hold with TCC and b) the monetized value of member activity. The
                    Company’s proprietary method of pairing these values together enables TCC to acquire and
                    hold new income-producing community-owned assets and generate additional revenue
                    through member activity.
                    TCC’s unique way of pairing
                    these existing assets with the
                    monetized value of member
                    activity creates a backstop of
                    diversified reserves that protects
                    the Community from losses
                    during market downturns. This
                    backstop enables TCC to
                    consistently multiply values for
                    the Community, its members,
                    and investors in any market
                    environment.
                </p>
                <p>
                Associate Members
                <ul>
                    <li>$8.88 per month</li>
                    <li>Access to life coaching</li>
                    <li>Access to buy and sell products in the marketplace</li>
                    <li>Access to member-only coupons at TCC businesses</li>
                    <li>Access to Learning Center materials, tuition-based virtual schools</li>
                    <li>Access to special-priced vacation destinations</li>
                </ul>
                Premium Members
                <ul>
                    <li>$25 per month for 36 months, then converted to $900 in stock shares</li>
                    <li>Access to life coaching</li>
                    <li>Access to buy and sell products in the marketplace</li>
                    <li>Access to member-only coupons at TCC businesses</li>
                    <li>Access to Learning Center materials, tuition-based virtual schools</li>
                    <li>Access to special-priced vacation destinations</li>
                    <li>Access to submit business proposals for TCC partnership funding</li>
                </ul>
                </p>
            </div>
        </div>
    )
}

export default LearnMore;