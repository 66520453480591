import react, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import '../Styles/createFormStyle.css';

function AddInitiative() {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [amountNeeded, setAmountNeeded] = useState(0);
    const [category, setCategory] = useState('');
    const [image, setImage] = useState(null);
    const [summary, setSummary] = useState('');
    const [location, setLocation] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const auth = getAuth();
    const db = getFirestore();
    const storage = getStorage();

    const categories = [
        'Technology', 'Real Estate', 'Transport', 'Travelling', 'Service', 'Construction',
        'Education', 'Media', 'Marketing', 'Fashion', 'Fitness',
    ]

    const countries = [
        'United States', 'United Kingdom', 'Canada', 'Australia', 'Germany',
        'France', 'India', 'China', 'Japan', 'Brazil', 'South Africa', 'Mexico',
    ];

    const handleImageUpload = async () => {
        if (!image) return null;

        const storageRef = ref(storage, `initiatives/${image.name}`);
        await uploadBytes(storageRef, image);
        return getDownloadURL(storageRef);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const user = auth.currentUser;

        try {
            const imageUrl = await handleImageUpload();
            const initiativeData = {
                title: title,
                description: body,
                author: user.uid,
                category: category,
                amountNeeded,
                location: location,
                amountReceived: 0,
                participants: [],
                isFeatured: false,
                status: 'Pending',
                image: imageUrl || null,
                summary: summary,
                isFeatured: false,
                type: '',
                contributors: [],
                visits: 0,
            };

            const initiativesCollectionRef = collection(db, 'Initiatives');
            await addDoc(initiativesCollectionRef, initiativeData);

            alert('Initiaitive added successfully!');
            setTitle('');
            setBody('');
            setImage(null);
            setSummary('');
        } catch (error) {
            console.error('Error adding Initiaitive:', error);
            alert('Error adding Initiaitive. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const validation = async (e) => {

    };

    return(
        <div className="addIntiativeContainer">
            <h1>Create Initiative Form</h1>
            <div className="createItemForm">
            <form onSubmit={handleSubmit} className="addInitiativeForm">
                    <label>
                        <p>Title:</p>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        <p>Summary:</p>
                        <input
                            type="text"
                            value={summary}
                            onChange={(e) => setSummary(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        <p>Body:</p>
                        <textarea
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        <p>Amount Needed:</p>
                        <input
                            type="number"
                            value={amountNeeded}
                            onChange={(e) => setAmountNeeded(Number(e.target.value))}
                            required
                        />
                    </label>
                    <label>
                        <p>Category:</p>
                        <select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            required
                        >
                            <option value="">Select Category</option>
                            {categories.map((cat) => (
                                <option key={cat} value={cat}>{cat}</option>
                            ))}
                        </select>
                    </label>
                    <label>
                        <p>Location:</p>
                        <select
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            required
                        >
                            <option value="">Select Location</option>
                            {countries.map((country) => (
                                <option key={country} value={country}>{country}</option>
                            ))}
                        </select>
                    </label>
                    <label>
                        <p>Image:</p>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </label>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Add Initiative'}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default AddInitiative