import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Get the initiative ID from the URL
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth } from 'firebase/auth';
import '../Styles/initiativeDetails.css';
import defaultInitiative from '../Images/defaultInitiative.png';

function InitiativeDetails() {
    const { id } = useParams(); // Extract initiative ID from the route
    const [initiative, setInitiative] = useState(null);
    const [author, setAuthor] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);

    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;

    const [isBacking, setIsBacking] = useState(false);
    const [amount, setAmount] = useState('');

    useEffect(() => {
        fetch("https://mmp3cpgdp3.eu-west-2.awsapprunner.com/config").then(async (r) => {
            const { publishableKey } = await r.json();
            setStripePromise(loadStripe(publishableKey));
        });
    }, []);

    useEffect(() => {
        const fetchInitiative = async () => {
            try {
                const initiativeDocRef = doc(db, "Initiatives", id);
                const initiativeDoc = await getDoc(initiativeDocRef);
                if (initiativeDoc.exists()) {
                    const initiativeData = initiativeDoc.data();
                    setInitiative(initiativeDoc.data());

                    if (initiativeData.author) {
                        fetchAuthor(initiativeData.author);
                    }
                } else {
                    console.error("Initiative not found");
                }
            } catch (error) {
                console.error("Error fetching initiative:", error);
            }
        };

        const fetchAuthor = async (authorId) => {
            try {
                const authorDocRef = doc(db, "Users", authorId);
                const authorDoc = await getDoc(authorDocRef);

                if (authorDoc.exists()) {
                    setAuthor(authorDoc.data());
                } else {
                    console.error("Author not found");
                }
            } catch (error) {
                console.error("Error fetching author:", error);
            }
        };

        fetchInitiative();
    }, [id]);

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        }).format(amount);
    };

    const handleBackClick = () => {
        setIsBacking(true);
    };

    const handleClose = () => {
        setIsBacking(false);
        setAmount(''); 
    };

    const handlePay = async () => {
        if (amount && parseFloat(amount) > 0) {
            try {
                // Call your backend to create the checkout session
                const response = await fetch("https://mmp3cpgdp3.eu-west-2.awsapprunner.com/create-checkout-session", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        amount: parseFloat(amount), // Send amount as a float
                        userId: user.uid, // Replace with the correct user ID from author data
                        initiativeId: id,
                        initiativeName: initiative.title,
                    }),
                });
    
                const data = await response.json();
    
                if (data.id && stripePromise) {
                    const stripe = await stripePromise;
                    await stripe.redirectToCheckout({ sessionId: data.id });
                } else {
                    console.error("Failed to create a Stripe session");
                }
            } catch (error) {
                console.error("Error during payment:", error);
            }
        } else {
            alert("Please enter a valid amount");
        }
    };    

    if (!initiative) {
        return <p>Loading initiative...</p>;
    }

    return (
        <div className="initiativeDetailsContainer">
            <h1>{initiative.title}</h1>
            <p className="initiativeSummary">{initiative.summary}</p>

            {isBacking && (
                <div className="backing-overlay">
                    <div className="backing-container">
                        <h2>Back this Initiative</h2>
                        <p>By backing this initiative, you are supporting the community and building points, you will receive 30% returns over the next 5 years for your contributions.</p>
                        <label>
                            <p>Enter amount: </p>
                            <input
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                placeholder="Enter amount"
                            />
                        </label>
                        <div className="backing-actions">
                            <button onClick={handlePay} className="pay-btn">Pay</button>
                            <button onClick={handleClose} className="cancel-btn">Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            <div className="initiativeGrid">
                <img className="bannerImage" src={initiative.image || defaultInitiative} alt={'Initiative Image'}/>
                
                <div className="fundingInfo">
                    {author ? (
                        <div className="authorDetails">
                            {author.profilePicture ? (
                                <img
                                    className="authorProfilePic"
                                    src={author.profilePicture}
                                    alt="Author Profile"
                                />
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" className="authorProfilePic" viewBox="0 0 512 512"><path fill="currentColor" fill-rule="evenodd" d="M256 42.667A213.333 213.333 0 0 1 469.334 256c0 117.821-95.513 213.334-213.334 213.334c-117.82 0-213.333-95.513-213.333-213.334C42.667 138.18 138.18 42.667 256 42.667m21.334 234.667h-42.667c-52.815 0-98.158 31.987-117.715 77.648c30.944 43.391 81.692 71.685 139.048 71.685s108.104-28.294 139.049-71.688c-19.557-45.658-64.9-77.645-117.715-77.645M256 106.667c-35.346 0-64 28.654-64 64s28.654 64 64 64s64-28.654 64-64s-28.653-64-64-64"/></svg>
                            )}
                            
                            <p className="authorName">{author.username}</p>
                        </div>
                    ) : (
                        <p>Loading author details...</p>
                    )}
                    <p><span className="pledgedAmount">{formatCurrency(initiative.amountReceived)} </span> <br />Pledged to Date </p>
                    <button className="backInitiative" onClick={handleBackClick}>Back this initiative</button>
                    <div className="btnRow">
                        <button className="remindBtn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M6 19.5V5.616q0-.691.463-1.153T7.616 4h8.769q.69 0 1.153.463T18 5.616V19.5l-6-2.577zm1-1.55l5-2.15l5 2.15V5.616q0-.231-.192-.424T16.384 5H7.616q-.231 0-.424.192T7 5.616zM7 5h10z"/>
                            </svg>
                            <p>Remind me</p>
                        </button>

                        <button className="shareBtn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M19.59 12L15 7.41v2.46l-.86.13c-4.31.61-7.23 2.87-8.9 6.33c2.32-1.64 5.2-2.43 8.76-2.43h1v2.69m-2-1.69v.02c-4.47.21-7.67 1.82-10 5.08c1-5 4-10 11-11V5l7 7l-7 7v-4.1c-.33 0-.66.01-1 .02Z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            
            <div className="initiativeInfoContainer">
                <h3>Project Description</h3>
                <p className="initiativeDesc">{initiative.description}</p>
            </div>
            
        </div>
    );
}

export default InitiativeDetails;
