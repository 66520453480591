import react, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc, deleteDoc, getDocs, collection, query, where } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';
import PaymentMethods from './PaymentMethods';
import '../Styles/profileStyle.css';
import defaultInitiative from '../Images/defaultInitiative.png';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

function Profile() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [profilePic, setProfilePic] = useState('');
    const [coverPic, setCoverPic] = useState('');
    const [points, setPoints] = useState(0);
    const [stripePromise, setStripePromise] = useState(null);

    const [newUsername, setNewUsername] = useState('');
    const [newFirstName, setNewFirstName] = useState('');
    const [newLastName, setNewLastName] = useState('');
    const [newAddress, setNewAddress] = useState('');
    const [profilePicFile, setProfilePicFile] = useState(null);
    const [coverPicFile, setCoverPicFile] = useState(null);

    const [initiatives, setInitiatives] = useState([]);
    const [magazines, setMagazines] = useState([]);

    const [tab, setTab] = useState('Profile');
    const [loading, setLoading] = useState(false);

    const auth = getAuth();
    const db = getFirestore();
    const storage = getStorage();

    //Fetch & Display user information
    const fetchData = async () => {
        const user = auth.currentUser;
        if (user) {
            const userDocRef = doc(db, "Users", user.uid);
            const userDoc = await getDoc(userDocRef);
            
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setUsername(userData.username);
                setEmail(userData.email);
                setFirstName(userData.firstName || '');
                setLastName(userData.lastName || '');
                setAddress(userData.address || '');
                setProfilePic(userData.profilePicture || '');
                setCoverPic(userData.coverPhoto || '');
                setPoints(userData.points || 0);
            } else {
                console.log(`User doc doesn't exist`)
            }
        }
    };

    useEffect(() => {
        fetch("https://mmp3cpgdp3.eu-west-2.awsapprunner.com/config").then(async (r) => {
            const { publishableKey } = await r.json();
            setStripePromise(loadStripe(publishableKey));
        });
    }, []);

    useEffect(() => {
        fetchData();
        fetchMyInitiatives();
        fetchMyMagazines();
    }, []);

    const calculateProgress = (amountReceived, amountNeeded) => {
        if (amountNeeded === 0) return 0;
        return (amountReceived / amountNeeded) * 100;
    };

    const uploadFile = async (file, path) => {
        const fileRef = ref(storage, path);
        await uploadBytes(fileRef, file);
        return getDownloadURL(fileRef);
    };

    const handleFileChange = (event) => {
        const { name, files } = event.target;
        if (name === 'profilePic') {
            setProfilePicFile(files[0]);
        } else if (name === 'coverPic') {
            setCoverPicFile(files[0]);
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        setLoading(true);
        
        try {
            const user = auth.currentUser;
            if (user) {
                let profilePicUrl = profilePic;
                let coverPicUrl = coverPic;

                if (profilePicFile) {
                    profilePicUrl = await uploadFile(profilePicFile, `profilePics/${user.uid}`);
                }

                if (coverPicFile) {
                    coverPicUrl = await uploadFile(coverPicFile, `coverPics/${user.uid}`);
                }

                const userDocRef = doc(db, "Users", user.uid);
                await updateDoc(userDocRef, {
                    username,
                    firstName,
                    lastName,
                    address,
                    profilePicture: profilePicUrl,
                    coverPhoto: coverPicUrl
                });

                fetchData(); // Refresh user data
            }
        } catch (error) {
            console.error("Error updating profile:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteAccount = async () => {
        const user = auth.currentUser;

        if (!user) return;

        // Show confirmation dialog
        const confirmation = window.confirm(
            "Are you sure you want to delete your account? This action cannot be undone."
        );

        if (!confirmation) return;

        setLoading(true);

        try {
            // Delete profile and cover pictures from storage (if available)
            if (profilePic) {
                const profilePicRef = ref(storage, `profilePics/${user.uid}`);
                await deleteObject(profilePicRef);
            }
            if (coverPic) {
                const coverPicRef = ref(storage, `coverPics/${user.uid}`);
                await deleteObject(coverPicRef);
            }

            // Delete user data from Firestore
            const userDocRef = doc(db, "Users", user.uid);
            await deleteDoc(userDocRef);

            // Delete user authentication
            await user.delete();

            alert("Your account has been successfully deleted.");
        } catch (error) {
            console.error("Error deleting account:", error);
            alert(
                "An error occurred while deleting your account. Please try again."
            );
        } finally {
            setLoading(false);
        }
    };

    const fetchMyInitiatives = async () => {
        const user = auth.currentUser;
    
        if (!user) {
            console.error("User is not logged in");
            return;
        }
    
        try {
            const initiativesCollectionRef = collection(db, "Initiatives");
            const q = query(initiativesCollectionRef, where("author", "==", user.uid));
            const querySnapshot = await getDocs(q);
    
            const initiatives = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
    
            setInitiatives(initiatives);
        } catch (error) {
            console.error("Error fetching initiatives:", error);
        }
    };

    const fetchMyMagazines = async (e) => {
        const user = auth.currentUser;
    
        if (!user) {
            console.error("User is not logged in");
            return;
        }
    
        try {
            const magazinesCollectionRef = collection(db, "Magazines");
            const q = query(magazinesCollectionRef, where("author", "==", user.uid));
            const querySnapshot = await getDocs(q);
    
            const magazines = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            setMagazines(magazines);
        } catch (error) {
            console.error("Error fetching initiatives:", error);
        }
    };

    const formatDate = (timestamp) => {
        const date = timestamp.toDate(); 
        return date.toLocaleDateString(); 
    };

    return (
        <div className="profileContainer">
            {/* <img 
                src={coverPic || ''} 
                alt="" 
                className="coverPhoto" 
            /> */}
            <div className="profileControlBtns">
                <button onClick={() => setTab('Profile')} className={tab === 'Profile' ? 'active' : ''}>Profile</button>
                <button onClick={() => setTab('Wallet')} className={tab === 'Wallet' ? 'active' : ''}>Wallet</button>
                <button onClick={() => setTab('Initiatives')} className={tab === 'Initiatives' ? 'active' : ''}>My Initiatives</button>
                <button onClick={() => setTab('Magazines')} className={tab === 'Magazines' ? 'active' : ''}>My Magazines</button>
            </div>
            
            {tab === 'Profile' && (
                <div className="mainProfile">
                    <div className="profilePic">
                        <div className="picContainer">
                            <img 
                                src={profilePic || ''} 
                                alt="" 
                            />
                            <div className="editBtnContainer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M20.71 7.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83l3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75z"/></svg>
                            </div>
                        </div>
                        
                        <div className="userDetailsContainer">
                            <div className="usernamePointContainer">
                                <h2>{username}</h2>
                                <h4>{points} Points</h4>
                            </div>
                            <p>{email}</p>
                        </div>
                        
                    </div>

                    <form onSubmit={handleUpdate}>
                        <div>
                            <label>First Name:</label>
                            <input 
                                type="text" 
                                value={firstName} 
                                onChange={(e) => setFirstName(e.target.value)} 
                            />
                        </div>
                        <div>
                            <label>Last Name:</label>
                            <input 
                                type="text" 
                                value={lastName} 
                                onChange={(e) => setLastName(e.target.value)} 
                            />
                        </div>
                        <div>
                            <label>Address:</label>
                            <input 
                                type="text" 
                                value={address} 
                                onChange={(e) => setAddress(e.target.value)} 
                            />
                        </div>
                        
                        <button className="updateUserDetails" type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save Profile'}
                        </button>
                        <button className="deleteAccountBtn"
                        onClick={handleDeleteAccount}
                        disabled={loading}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6zM8 9h8v10H8zm7.5-5l-1-1h-5l-1 1H5v2h14V4z"/>
                            </svg>
                            <p>Delete Account</p>
                        </button>
                    </form>
                </div>
            )}

            {tab === 'Wallet' && (
                <div className="walletContainer">
                    <h2>Wallet</h2>
                    <p>These card details will be used to process your subscription.</p>
                    <Elements stripe={stripePromise} >
                        <PaymentMethods />
                    </Elements>
                </div>
            )}
            {tab === 'Initiatives' && (
                <div className="myInitiatives">
                    <h2>My Initiatives</h2>
                    <div className="featuredInitiativeContainer">
                        {initiatives.length > 0 ? (
                            initiatives.map((initiative) => (
                                <Link 
                                    key={initiative.id} 
                                    to={`/initiative/${initiative.id}`}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <div key={initiative.id} className="initiativeCard">
                                        <div className="bannerProgressContainer">
                                            <img className="bannerImage" src={initiative.cover || defaultInitiative} alt={'Initiative Image'}/>
                                            <div className="progressBarContainer">
                                                <div 
                                                    className="progressBarFill"
                                                    style={{ width: `${calculateProgress(initiative.amountReceived, initiative.amountNeeded)}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                        
                                        <div className="content">
                                            <h2>{initiative.title} <span className={initiative.status}>{initiative.status}</span></h2>
                                            <p>{initiative.description}</p>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        ) : (
                            <p>No initiatives found.</p>
                        )}
                    </div>
                </div>
            )}
            {tab === 'Magazines' && (
                <div className="myMagazines">
                    <h2>My Magazines</h2>
                    <div className="magazineContainer">
                        {magazines.length > 0 ? (
                            magazines.map((magazine) => (
                                <Link 
                                    key={magazine.id} 
                                    to={`/magazine/${magazine.id}`}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <div key={magazine.id} className="magazineCard">
                                        {magazine.image ? (
                                            <img src={magazine.image} alt="Magazine cover photo" className="magazineImage" />
                                        ) : (
                                            <div className="magazineImage">
                                                <h2>TCC <br /> Magazine</h2>
                                            </div>
                                        )}
                                        <div className="magazineDetails">
                                            <h2>{magazine.title} - {formatDate(magazine.date)} <span className={magazine.status}>{magazine.status}</span></h2>
                                            <p>{magazine.summary}</p>
                                            <button>Read more...</button>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        ) : (
                            <p>No Magazines.</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Profile;