import react, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; 
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import '../Styles/magazineDetails.css';

function MagazineDetails() {
    const { id } = useParams();
    const [magazine, setMagazine] = useState(null);
    const [author, setAuthor] = useState(null);
    const db = getFirestore();

    useEffect(() => {
        const fetchMagazine = async () => {
            try {
                const magazineDocRef = doc(db, "Magazines", id);
                const magazineDoc = await getDoc(magazineDocRef);
                if (magazineDoc.exists()) {
                    const magazineData = magazineDoc.data();
                    setMagazine(magazineDoc.data());

                    if (magazineData.author) {
                        fetchAuthor(magazineData.author);
                    }
                } else {
                    console.error("magazine not found");
                }
            } catch (error) {
                console.error("Error fetching magazine:", error);
            }
        };

        const fetchAuthor = async (authorId) => {
            try {
                const authorDocRef = doc(db, "Users", authorId);
                const authorDoc = await getDoc(authorDocRef);

                if (authorDoc.exists()) {
                    setAuthor(authorDoc.data());
                } else {
                    console.error("Author not found");
                }
            } catch (error) {
                console.error("Error fetching author:", error);
            }
        };

        fetchMagazine();
    }, [id]);

    const formatDate = (timestamp) => {
        const date = timestamp.toDate(); 
        return date.toLocaleDateString(); 
    };

    if (!magazine) {
        return <p>Loading magazine...</p>;
    }

    return (
        <div className="magazineDetailsContainer">
            {magazine.image ? (
                <img src={magazine.image} alt="Magazine cover photo" className="magazineImage" />
            ) : (
                <div className="magazineImage">
                    <h2>TCC <br /> Magazine</h2>
                </div>
            )}

            <div className="magazineHeader">
                <h1>{magazine.title}</h1>
                <p>{magazine.summary}</p>
                <p className="magazineDate">{formatDate(magazine.date)}</p>
            </div>
            

            <div className="magazineBody">
                <p>{magazine.body}</p>
            </div>
            
        </div>
    );
}

export default MagazineDetails;