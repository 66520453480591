import './App.css';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import React, { useEffect, useState } from 'react';
import { auth } from './firebase';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';

function App() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((currentUser) => {
          setUser(currentUser);
          if (currentUser) {
            // Navigate to /home when user logs in
            navigate('/home');
        }

      });

      return () => unsubscribe();
  }, []);

  return (
    <div className="App">
      {user ? <Dashboard /> : <Login />}
    </div>
  );
}

export default App;
