import react, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import '../Styles/homeStyle.css';
import { Link } from 'react-router-dom';
import defaultInitiative from '../Images/defaultInitiative.png';
import SubscriptionWall from './SubscriptionWall'; 

function Home() {
    const [featuredInitiatives, setFeaturedInitiatives] = useState([]);
    const [featuredMagazines, setFeaturedMagazines] = useState([]);
    const [isSubscribed, setIsSubscribed] = useState(null);

    const auth = getAuth();
    const db = getFirestore();
    const storage = getStorage();

    const calculateProgress = (amountReceived, amountNeeded) => {
        if (amountNeeded === 0) return 0;
        return (amountReceived / amountNeeded) * 100;
    };

    const fetchInitiatives = async () => {
        try {
            const initiativesCollectionRef = collection(db, "Initiatives");
            const querySnapshot = await getDocs(initiativesCollectionRef);
            const initiativesData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            const featured = initiativesData.filter(initiative => initiative.isFeatured);

            setFeaturedInitiatives(featured);
        } catch (error) {
            console.error("Error fetching initiatives:", error);
        }

    };

    const fetchMagazines = async () => {
        try {
            const magazinesCollectionRef = collection(db, "Magazines");
            const querySnapshot = await getDocs(magazinesCollectionRef);
            const magazinesData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            const featured = magazinesData.filter(magazine => magazine.isFeatured);
            setFeaturedMagazines(featured);
        } catch (error) {
            console.log("Error fetching magazines:", error);
        }
    };

    const checkSubscription = async () => {
        try {
            const user = auth.currentUser;
            const userDocRef = doc(db, "Users", user.uid);
            const userDoc = await getDoc(userDocRef);
            const userData = userDoc.data();

            if (userData.batch === 'Batch1') {
                setIsSubscribed(true);
                return;
            }

            if (!user) {
                setIsSubscribed(false);
                return;
            }

            const response = await fetch('https://mmp3cpgdp3.eu-west-2.awsapprunner.com/check-any-subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userEmail: user.email }),
            });

            const data = await response.json();
            setIsSubscribed(data.isSubscribedToAnyItem);
        } catch (error) {
            console.error('Error checking subscription status:', error);
            setIsSubscribed(false); // In case of an error, assume not subscribed
        }
    };

    const formatDate = (timestamp) => {
        const date = timestamp.toDate(); 
        return date.toLocaleDateString(); 
    };

    useEffect(() => {
        fetchInitiatives();
        fetchMagazines();
        checkSubscription();
    }, []);

    if (!isSubscribed) {
        return <SubscriptionWall />;
    }

    return (
        <div className="homeContainer homeSection">
            <div className="howTccWorksBlog">
                <div className="textContainer">
                    <h1>Take back control of your finances.</h1>
                    <p>TCC boldly create access to limitless opportunities for vetted and valiantly imaginative people
                    passionate about building beautiful, absolutely free, superabundant communities.</p>
                    <Link to={`/learn-more`} 
                        style={{
                            textDecoration: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            padding: '10px 20px',
                            backgroundColor: '#560BFF',
                            color: 'white',
                            borderRadius: '10px',
                            textAlign: 'center',
                          }}>Learn more</Link>
                </div>
            </div>

            <div className="featuredInitiativesHome homeSection">
                <h2 className="initiativesTitle">
                    Featured initiatives 
                    <span className="seeMore">
                        <Link 
                        to={`/initiatives/`} 
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            cursor: 'pointer',
                            background: 'none',
                            border: 'none',
                            color: '#560BFF',
                            textDecoration: 'none',
                            fontSize: '18px',
                            columnGap: '10px'
                        }}
                        >
                        <p>See More</p> 
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="currentColor" fill-rule="evenodd" d="M13.47 5.47a.75.75 0 0 1 1.06 0l6 6a.75.75 0 0 1 0 1.06l-6 6a.75.75 0 1 1-1.06-1.06l4.72-4.72H4a.75.75 0 0 1 0-1.5h14.19l-4.72-4.72a.75.75 0 0 1 0-1.06" clip-rule="evenodd"/>
                        </svg>
                        </Link>
                    </span>
                </h2>
                <div className="featuredInitiativeContainer">
                    {featuredInitiatives.length > 0 ? (
                        featuredInitiatives.map((initiative) => (
                            <Link 
                                key={initiative.id} 
                                to={`/initiative/${initiative.id}`}
                                style={{ textDecoration: 'none' }}
                            >
                                <div key={initiative.id} className="initiativeCard">
                                    <div className="bannerProgressContainer">
                                        <img className="bannerImage" src={initiative.cover || defaultInitiative} alt={'Initiative Image'}/>
                                        <div className="progressBarContainer">
                                            <div 
                                                className="progressBarFill"
                                                style={{ width: `${calculateProgress(100, 100)}%` }}
                                            ></div>
                                        </div>
                                    </div>
                                    
                                    <div className="content">
                                        <h2>{initiative.title}</h2>
                                        <p>{initiative.description}</p>
                                    </div>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <p>No initiatives found.</p>
                    )}
                </div>
            </div>
            
            <div className="magazineHome homeSection">
                <h2>Magazines from TCC 
                    <span className="seeMore">
                        <Link 
                        to={`/magazine/`} 
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            cursor: 'pointer',
                            background: 'none',
                            border: 'none',
                            color: '#560BFF',
                            textDecoration: 'none',
                            fontSize: '18px',
                            columnGap: '10px'
                        }}
                        >
                        <p>See More</p> 
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="currentColor" fill-rule="evenodd" d="M13.47 5.47a.75.75 0 0 1 1.06 0l6 6a.75.75 0 0 1 0 1.06l-6 6a.75.75 0 1 1-1.06-1.06l4.72-4.72H4a.75.75 0 0 1 0-1.5h14.19l-4.72-4.72a.75.75 0 0 1 0-1.06" clip-rule="evenodd"/>
                        </svg>
                        </Link>
                    </span>
                </h2>
                <div className="featuredInitiativeContainer">
                    {featuredMagazines.length > 0 ? (
                        featuredMagazines.map((magazine) => (
                            <Link 
                                key={magazine.id} 
                                to={`/magazine/${magazine.id}`}
                                style={{ textDecoration: 'none' }}
                            >
                                <div key={magazine.id} className="magazineCard">
                                    {magazine.image ? (
                                        <img src={magazine.image} alt="Magazine cover photo" className="magazineImage" />
                                    ) : (
                                        <div className="magazineImage">
                                            <h2>TCC <br /> Magazine</h2>
                                        </div>
                                    )}
                                    <div className="magazineDetails">
                                        <h2>{magazine.title} - {formatDate(magazine.date)}</h2>
                                        <p>{magazine.summary}</p>
                                        <button>Read more...</button>
                                    </div>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <p>No Magazines.</p>
                    )}
                </div>
            </div>
        </div>
    )
};

export default Home;