import react from 'react';
import '../Styles/allowancesStyle.css';

function Allowanaces() {

    return(
        <div className="AllowancesContainer">
            <h1>Allowances</h1>
            <p>Lifestyle Allowance is just one of the limitless opportunities accessible to TCC Members to fund their
lifestyles by recycling the value of their money over and over instead of spending it into oblivion. Below is
one example of a TCC Member doing $100 a month. The variations are limitless.</p>
            <table className="allowancesTable">
                <thead>
                <tr>
                    <th>Example</th>
                    <th>Year 1</th>
                    <th>Year 2</th>
                    <th>Year 3</th>
                    <th>Year 4</th>
                    <th>Year 5</th>
                    <th>Totals</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>A TCC Member pays forward $100 a month</td>
                    <td>$1,200</td>
                    <td>$1,200</td>
                    <td>$1,200</td>
                    <td>$1,200</td>
                    <td>$1,200</td>
                    <td>$6,000.00</td>
                </tr>
                <tr>
                    <td>5% premium added after month 60</td>
                    <td colSpan="5"></td>
                    <td>$300.00</td>
                </tr>
                <tr>
                    <td>TCC pays backward $30.77 monthly</td>
                    <td>$369.23</td>
                    <td>$369.23</td>
                    <td>$369.23</td>
                    <td>$369.23</td>
                    <td>$369.23</td>
                    <td>$1,846.15</td>
                </tr>
                <tr>
                    <td>Total premium and savings</td>
                    <td colSpan="5"></td>
                    <td>$2,146.15</td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Allowanaces;